* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
}

.scroll {
    overflow: auto;

    /* firefox*/
    scrollbar-width: thin;
    scrollbar-color: #999 #fff;

}

/* chrome */
.scroll::-webkit-scrollbar {
    width: 0.4em;
}

.scroll::-webkit-scrollbar-track {
    background: #fff
}

.scroll::-webkit-scrollbar-thumb {
    background-color: #999;
}
